import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';

import { PrescriptionLayoutFormWrapperProps } from 'components/prescription-layout-form-wrapper/prescription-layout-form-wrapper.props';

import './prescription-layout-form-wrapper.style.scss';
import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';
import WorkflowSteps from 'ui-kit/workflow-steps/workflow-steps';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'ui-kit/button/button';
import { addTransferPrescriptionRoutine } from 'state/add-transfer-prescription/add-transfer-prescription.routines';
import {
    addTransferPrescriptionFlowTypeSelector,
    addTransferPrescriptionSelector,
    addTransferPrescriptionStepSelector,
    addTransferPrescriptionTotalStepSelector
} from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import {
    PrescriptionPayload,
    setShowAddressStep,
    setShowPaymentStep,
    setStep
} from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import { HealthConditionPills } from 'components/health-conditions/health-conditions.component';
import { useHealthConditions } from 'hooks/useHealthConditions';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { OverflowModal } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import Disclaimer from 'components/disclaimer/disclaimer.component';
import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';
import {
    accountFetchHealthConditionsRoutine,
    accountFetchProfileRoutine,
    accountGetAllCreditCardsRoutine
} from 'state/account/account.routines';
import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';
import { TrackVirtualPageView } from 'util/google_optimize/optimize_helper';

const pillBottle = require('../../assets/icons/pillBottle.svg');

const FormNavigation = ({
    showNext,
    finalStep,
    formName,
    formStepName,
    currentStepNumber,
    handleNextClick,
    handleSubmitClick,
    translation
}: {
    showNext: any;
    finalStep: any;
    formName?: string;
    formStepName?: string;
    currentStepNumber: number;
    handleNextClick: (() => void) | undefined;
    handleSubmitClick: any;
    translation: TFunction<string>;
}) => {
    const currentFlowType = useSelector(addTransferPrescriptionFlowTypeSelector);
    const { userHasNotSubmittedAllergies, userHasNotSubmittedConditions } = useHealthConditions();
    if (showNext && !finalStep) {
        return (
            <>
                <Button
                    label={translation('prescriptionLayout.next')}
                    dataGAFormName={formName}
                    dataGAFormStepName={`${currentStepNumber}-${formStepName}`}
                    type="submit"
                    onClick={handleNextClick}
                    disabled={userHasNotSubmittedConditions || userHasNotSubmittedAllergies}
                />
            </>
        );
    } else if (finalStep) {
        return (
            <Button
                label={
                    currentFlowType === 'Transfer'
                        ? translation('prescriptionLayout.placeOrder')
                        : translation('prescriptionLayout.submit')
                }
                dataGAFormName={formName}
                dataGAFormStepName={`${currentStepNumber}-PrescriptionConfirmation`}
                type="submit"
                onClick={handleSubmitClick}
            />
        );
    } else {
        return <></>;
    }
};

const formatPhoneNumber = (f: string) => {
    const f_val = f.replace(/\D/g, '').substr(-10);
    return f_val.slice(0, 3) + '-' + f_val.slice(3, 6) + '-' + f_val.slice(6);
};

const WorkFlowFinalStep = ({ details }: { details: PrescriptionPayload }) => {
    const { t } = useTranslation();
    const { existingAllergies, existingConditions } = useHealthConditions();
    const {
        DrugName,
        Qty,
        Strength,
        DrugForm,
        PrescriberName,
        PrescriberAddress,
        PrescriberPhone,
        PrescriberFax,
        PrescriberCity,
        PrescriberState,
        PrescriberZip,
        PharmacyAddress,
        PharmacyName,
        PharmacyPhone,
        RxNumber
    } = details;
    return (
        <div className="prescription-layout__final-step">
            <div className="final-step__details final-step__section">
                <h3 className="final-step__heading">{t('prescriptionLayout.finalStep.prescriptionDetails')}</h3>
                <h3 className="final-step__subheading">{DrugName}</h3>
                <p className="final-step__content">
                    <small>
                        {t('prescriptionLayout.finalStep.qty', { Qty: Qty })}{' '}
                        <span className="final-step__punctuation">&middot;</span> {Strength}{' '}
                        <span className="final-step__punctuation">&middot;</span> {DrugForm}
                        {RxNumber && (
                            <>
                                {' '}
                                <span className="final-step__punctuation">&middot;</span> {RxNumber}
                            </>
                        )}
                    </small>
                </p>
            </div>
            <Row>
                {PharmacyName && PharmacyPhone && PharmacyAddress && (
                    <Col xs={12} md={6}>
                        <div className="final-step__details final-step__section">
                            <h3 className="final-step__heading">{t('prescriptionLayout.finalStep.pharmacy')}</h3>
                            <h3 className="final-step__subheading">{PharmacyName}</h3>
                            <p className="final-step__content">
                                <span className="d-flex">
                                    <small>
                                        {t('prescriptionLayout.finalStep.prescriberPhone', {
                                            PrescriberPhone: formatPhoneNumber(PharmacyPhone)
                                        })}
                                    </small>
                                </span>
                                <span className="d-flex">
                                    <small>{PharmacyAddress}</small>
                                </span>
                            </p>
                        </div>
                    </Col>
                )}
                <Col xs={12} md={PharmacyName && PharmacyPhone ? 6 : 12}>
                    <div className={`final-step__prescriber final-step__section`}>
                        <h3 className="final-step__heading">{t('prescriptionLayout.finalStep.prescriber')}</h3>
                        <h3 className="final-step__subheading">{PrescriberName}</h3>
                        <p className="final-step__content">
                            <span className="d-flex">
                                <small>
                                    {t('prescriptionLayout.finalStep.prescriberPhone', {
                                        PrescriberPhone: formatPhoneNumber(PrescriberPhone)
                                    })}
                                </small>
                            </span>
                            {PrescriberFax.length !== 0 && (
                                <span className="d-flex">
                                    <small>
                                        {t('prescriptionLayout.finalStep.prescriberFax', {
                                            PrescriberFax: formatPhoneNumber(PrescriberFax)
                                        })}
                                    </small>
                                </span>
                            )}
                            <span className="d-flex">
                                <small>{PrescriberAddress}</small>
                            </span>
                            <span className="d-flex">
                                <small>
                                    {PrescriberCity}, {PrescriberState} {PrescriberZip}
                                </small>
                            </span>
                        </p>
                        <p className="final-step__content" />
                    </div>
                </Col>
            </Row>

            <div className="final-step__health-profile final-step__section">
                <h3 className="final-step__heading">{t('prescriptionLayout.finalStep.healthProfile')}</h3>
                <div className="health-profile__conditions">
                    <h4 className="health-profile__subhead">{t('accordions.healthConditions.title')}</h4>
                    <HealthConditionPills conditions={existingConditions} />
                </div>
                <div className="health-profile__conditions">
                    <h4 className="health-profile__subhead">{t('accordions.allergies.title')}</h4>
                    <HealthConditionPills conditions={existingAllergies} />
                </div>
            </div>
            <div className="final-step__disclaimer-container">
                <Disclaimer disclaimerText={t('prescriptionLayout.finalStep.disclaimer')} />
            </div>
        </div>
    );
};

const PrescriptionLayoutFormWrapper = ({
    children,
    className,
    eyebrowText,
    flowType,
    steps
}: PrescriptionLayoutFormWrapperProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [finalStep, setFinalStep] = useState(false);
    const [showNext, setShowNext] = useState(true);
    const [currentBackgroundImage, setCurrentBackgroundImage] = useState(steps[0].backgroundImage);
    const currentStepState = useSelector(addTransferPrescriptionStepSelector);
    const totalStepState = useSelector(addTransferPrescriptionTotalStepSelector);
    const transferPrescriptionState = useSelector(addTransferPrescriptionSelector);
    const currentFlowType = useSelector(addTransferPrescriptionFlowTypeSelector);
    const { incrementStep, decrementStep, resetForm, setPrescriptionFlowType } = useAddTransferPrescription();
    const { checkPaymentExists, checkShippingExists, setTotalNumberOfSteps } = useAddTransferPrescription();
    const firstStep = currentStepState === 0;

    useEffect(() => {
        if (currentFlowType === '') {
            navigate('/secure/medicine-cabinet');
        }
    }, [currentFlowType]);

    useEffect(() => {
        dispatch(accountFetchProfileRoutine.trigger());
        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: true }));
        dispatch(accountGetAllCreditCardsRoutine.trigger());
        dispatch(accountFetchHealthConditionsRoutine.trigger());
        dispatch(setShowPaymentStep({ showPaymentStep: false }));
        dispatch(setShowAddressStep({ showAddressStep: false }));
        dispatch(setStep({ currentStep: 0 }));
    }, []);

    useEffect(() => {
        switch (currentFlowType) {
            case 'Transfer':
                setTotalNumberOfSteps(5)(checkPaymentExists())(checkShippingExists());
                break;
            default:
                setTotalNumberOfSteps(4)(checkPaymentExists())(checkShippingExists());
                break;
        }
    }, []);

    const imageData = useStaticQuery(graphql`
        query {
            successModalPillImage: file(relativePath: { eq: "assets/images/hero-pill-image-sample.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            newRxConfirm: file(relativePath: { eq: "assets/images/new-rx-confirm.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            transferRxConfirm: file(relativePath: { eq: "assets/images/transfer-rx-confirm.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    useEffect(() => {
        setFinalStep(currentStepState === totalStepState - 1);
    }, [currentStepState]);

    useEffect(() => {
        if (currentStepState !== totalStepState - 1) {
            setCurrentBackgroundImage(steps[currentStepState].backgroundImage);
            setShowNext(steps[currentStepState].showNextButton);
        } else {
            if (currentFlowType === 'New') {
                setCurrentBackgroundImage(imageData.newRxConfirm);
            } else {
                setCurrentBackgroundImage(imageData.transferRxConfirm);
            }
        }

        let pagePath = '';
        if (steps[currentStepState] && steps[currentStepState].gtmVPV) {
            const gtmVPV = steps[currentStepState].gtmVPV;
            pagePath = gtmVPV.pagePath;
        } else {
            pagePath = '/confirm';
        }
        TrackVirtualPageView(
            `vpv${currentFlowType}Prescription`,
            currentFlowType,
            currentFlowType === 'New' ? `/request${pagePath}` : `/transfer${pagePath}`
        );
    }, [currentStepState]);

    const classes = classNames('prescription-layout-form-wrapper', className);

    const handleNextClick = () => {
        incrementStep();
    };

    const handleBackClick = () => {
        decrementStep();
    };

    const handleSubmitClick = () => {
        dispatch(
            addTransferPrescriptionRoutine.trigger({
                onSuccess: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            contentClassName: 'overflow-modal',
                            onClose: () => navigate('/secure/medicine-cabinet'),
                            bodyContent: (
                                <OverflowModal
                                    title={t('modals.newRX.title')}
                                    text={t('modals.newRX.followUp')}
                                    image={imageData.successModalPillImage}
                                    internalCtas={[
                                        {
                                            label: t('modals.newRX.labels.transferAPrescription'),
                                            variant: 'primary',
                                            onClick: () => {
                                                resetForm();
                                                setPrescriptionFlowType({ flowType: 'Transfer' });
                                                setTotalNumberOfSteps(5)(checkPaymentExists())(checkShippingExists());
                                                navigate('/secure/prescription');
                                                dispatch(closeModal({}));
                                            }
                                        },
                                        {
                                            label: t('modals.newRX.labels.requestNewPrescription'),
                                            variant: 'primary',
                                            onClick: () => {
                                                resetForm();
                                                setPrescriptionFlowType({ flowType: 'New' });
                                                setTotalNumberOfSteps(4)(checkPaymentExists())(checkShippingExists());
                                                navigate('/secure/prescription');
                                                dispatch(closeModal({}));
                                            }
                                        }
                                    ]}
                                />
                            ),
                            ctas: []
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            contentClassName: 'overflow-modal',
                            bodyContent: (
                                <OverflowModal
                                    title={t('modals.newRX.error')}
                                    text={t('modals.newRX.failure')}
                                    image={imageData.successModalPillImage}
                                    internalCtas={[
                                        {
                                            label: t('modals.newRX.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                                navigate('/secure/medicine-cabinet');
                                            }
                                        }
                                    ]}
                                />
                            ),
                            onClose: () => navigate('/secure/medicine-cabinet'),
                            ctas: []
                        })
                    );
                }
            })
        );
    };
    return (
        <WorkflowLayout backgroundImage={currentBackgroundImage}>
            <div className={classes}>
                <Container fluid>
                    <Row
                        className={`prescription-layout-form-wrapper-header d-flex flex-column ${
                            finalStep ? `prescription-layout-form-wrapper-header--final-step` : ``
                        }`}
                    >
                        {!firstStep && !finalStep && (
                            <Col className="p-0 col-12 mb-4 mb-md-0">
                                <Button
                                    variant="back"
                                    label={t('prescriptionLayout.back')}
                                    type="submit"
                                    onClick={handleBackClick}
                                />
                            </Col>
                        )}
                        {currentStepState === totalStepState && (
                            <div className="final-step__header-icon-container">
                                <img className="final-step__header-icon" src={pillBottle} alt={`Dashboard CTA Icon`} />
                            </div>
                        )}
                        <Col className="d-flex flex-column justify-content-center align-items-center col-12">
                            {eyebrowText && <h5>{eyebrowText}</h5>}
                            {steps.map((step, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {currentStepState === index && <h1 className="h2">{step.title}</h1>}
                                    </React.Fragment>
                                );
                            })}
                            {finalStep && (
                                <h1 className="prescription-layout__final-step-header">
                                    {t('pages.addNewPrescription.titles.confirmYourDetails')}
                                </h1>
                            )}
                            <div className="spacer" />
                            {flowType === 'Transfer' && currentStepState === 0 && (
                                <>
                                    <Button
                                        variant="outline-primary"
                                        label={t('pages.transferPrescription.addPhoto')}
                                        type="button"
                                        className="justify-content-center btn-camera d-flex align-items-center mb-1"
                                        onClick={() => {
                                            navigate('/secure/prescription/upload-photo');
                                        }}
                                        cameraIcon
                                    />
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="prescription-layout-form-wrapper-content">
                            {steps.map((step, index) => (
                                <React.Fragment key={index}>
                                    {currentStepState === index && <Col>{step.step}</Col>}
                                </React.Fragment>
                            ))}
                            {finalStep && <WorkFlowFinalStep details={transferPrescriptionState} />}
                            <Row className="d-flex justify-content-center mt-3">
                                <FormNavigation
                                    showNext={showNext}
                                    finalStep={finalStep}
                                    currentStepNumber={currentStepState + 1}
                                    formName={
                                        steps[currentStepState]?.dataGAFormName
                                            ? steps[currentStepState]?.dataGAFormName
                                            : `${flowType}Prescription`
                                    }
                                    formStepName={steps[currentStepState]?.dataGAFormStepName}
                                    handleNextClick={handleNextClick}
                                    handleSubmitClick={handleSubmitClick}
                                    translation={t}
                                />
                            </Row>
                            <Row className="d-flex justify-content-center mt-3">
                                {steps.map((step, index) => (
                                    <React.Fragment key={index}>
                                        {currentStepState === index && (
                                            <WorkflowSteps
                                                id="add-transfer-rx-page"
                                                activeStep={currentStepState + 1}
                                                numberOfSteps={totalStepState}
                                                activeStepName={step.title.replace(/\s/g, '-')}
                                            />
                                        )}
                                    </React.Fragment>
                                ))}
                            </Row>
                            {finalStep && (
                                <Row className="d-flex justify-content-center mt-3">
                                    <Button
                                        variant="text-blue"
                                        label={t('prescriptionLayout.back')}
                                        type="submit"
                                        className="justify-content-center"
                                        onClick={handleBackClick}
                                    />
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Container>
                {children}
            </div>
        </WorkflowLayout>
    );
};

export default PrescriptionLayoutFormWrapper;
