import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import {
    addTransferPrescriptionFlowTypeSelector,
    addTransferPrescriptionSelector
} from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { PrescriptionInfoFormProps } from './prescription-info-form.props';
import { PrescriptionTypes } from 'types/prescription';
import { NEW_PRESCRIPTION_SCHEMA, TRANSFER_PRESCRIPTION_SCHEMA } from 'schema/prescription.schema';

import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';
import Tooltip from 'ui-kit/tooltip/tooltip';

const PrescriptionInfoForm = ({ className, handleFormSubmit }: PrescriptionInfoFormProps) => {
    const classes = classNames(className);
    const { t } = useTranslation();
    const formName = 'PrescriptionInfoForm';
    const transferPrescriptionState = useSelector(addTransferPrescriptionSelector);
    const currentFlowType = useSelector(addTransferPrescriptionFlowTypeSelector);

    const handleSubmit = (values: Partial<PrescriptionTypes>) => {
        handleFormSubmit(values as PrescriptionTypes);
    };

    return (
        <div className={classes}>
            <Row>
                <Col>
                    <Formik<Partial<PrescriptionTypes>>
                        onSubmit={handleSubmit}
                        validationSchema={() => {
                            return currentFlowType === 'New'
                                ? NEW_PRESCRIPTION_SCHEMA()
                                : TRANSFER_PRESCRIPTION_SCHEMA();
                        }}
                        initialValues={{
                            drugName: transferPrescriptionState.DrugName.length
                                ? transferPrescriptionState.DrugName
                                : '',
                            drugForm: transferPrescriptionState.DrugForm ? transferPrescriptionState.DrugForm : '',
                            strength: transferPrescriptionState.Strength ? transferPrescriptionState.Strength : '',
                            qty: transferPrescriptionState.Qty ? transferPrescriptionState.Qty : '',
                            rxNumber: transferPrescriptionState.RxNumber ? transferPrescriptionState.RxNumber : ''
                        }}
                    >
                        {(formik: any) => (
                            <Form
                                id="prescription-info-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <Row>
                                    <Col className="d-flex justify-content-start">
                                        <p className="form-instructions">{t('prescriptionInfoForm.labels.drugName')}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Text
                                            name="drugName"
                                            label={t('prescriptionInfoForm.inputs.drugName')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.drugName
                                                    ? t('forms.errorMessages.requiredField', {
                                                          label: t('prescriptionInfoForm.inputs.drugName')
                                                      })
                                                    : undefined
                                            }
                                            touched={formik.touched.drugName}
                                            value={formik.values?.drugName}
                                            defaultValue={formik.initialValues.drugName}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-start">
                                        <p className="form-instructions">
                                            {t('prescriptionInfoForm.labels.rxDetails')}
                                        </p>
                                    </Col>
                                </Row>
                                <Row xs={1} lg={3}>
                                    <Col>
                                        <Text
                                            name="drugForm"
                                            label={t('prescriptionInfoForm.inputs.drugForm')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.drugForm
                                                    ? t('forms.errorMessages.requiredField', {
                                                          label: t('prescriptionInfoForm.inputs.drugForm')
                                                      })
                                                    : undefined
                                            }
                                            footNote={<small>{t('prescriptionInfoForm.inputs.drugFormNote')}</small>}
                                            touched={formik.touched.drugForm}
                                            value={formik.values?.drugForm}
                                            defaultValue={formik.initialValues.drugForm}
                                        />
                                    </Col>
                                    <Col>
                                        <Text
                                            name="strength"
                                            label={t('prescriptionInfoForm.inputs.strength')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.strength
                                                    ? formik.values.strength === ''
                                                        ? t('forms.errorMessages.requiredField', {
                                                              label: t('prescriptionInfoForm.inputs.strength')
                                                          })
                                                        : t('forms.errorMessages.noNegativeValue', {
                                                              field: t('prescriptionInfoForm.inputs.strength')
                                                          })
                                                    : undefined
                                            }
                                            touched={formik.touched.strength}
                                            value={formik.values?.strength}
                                            defaultValue={formik.initialValues.strength}
                                        />
                                    </Col>
                                    <Col>
                                        <Text
                                            name="qty"
                                            label={t('prescriptionInfoForm.inputs.qty')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.qty
                                                    ? formik.values.qty === ''
                                                        ? t('forms.errorMessages.requiredField', {
                                                              label: t('prescriptionInfoForm.inputs.qty')
                                                          })
                                                        : t('forms.errorMessages.nonNumericalValue', {
                                                              field: t('prescriptionInfoForm.inputs.qty')
                                                          })
                                                    : undefined
                                            }
                                            touched={formik.touched.qty}
                                            value={formik.values?.qty}
                                            defaultValue={formik.initialValues.qty}
                                        />
                                    </Col>
                                    {currentFlowType === 'Transfer' && (
                                        <Col xs={12} md={12} lg={12}>
                                            <Text
                                                name="rxNumber"
                                                label={t('prescriptionInfoForm.inputs.rxNumber')}
                                                onChange={formik.handleChange}
                                                errors={
                                                    formik.errors?.rxNumber
                                                        ? t('forms.errorMessages.requiredField', {
                                                              label: t('prescriptionInfoForm.inputs.rxNumber')
                                                          })
                                                        : undefined
                                                }
                                                touched={formik.touched.rxNumber}
                                                value={formik.values?.rxNumber}
                                                defaultValue={formik.initialValues.rxNumber}
                                            />
                                            <div className="tooltip-wrapper">
                                                <Tooltip tip={t('prescriptionInfoForm.inputs.rxNumberToolTip')} />
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                <Row>
                                    <Col className="d-flex flex-column align-items-center">
                                        <Button
                                            label={t('prescriptionLayout.next')}
                                            variant="primary"
                                            type="submit"
                                            dataGAFormName={formName}
                                            dataGAFormStep="1-Prescription"
                                            dataGAFormStepName="Prescription"
                                            disabled={!formik.values.drugName.length && !formik.dirty}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </div>
    );
};

export default PrescriptionInfoForm;
