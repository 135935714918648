import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { HealthConditionsWarningModalContent } from 'pages/secure/profile/health-profile';

import withSecurity from 'hoc/withSecurity';

import { useHealthConditions } from 'hooks/useHealthConditions';
import { useAddressVerification } from 'hooks/useAddressVerification';
import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { ProfileObjectAddressPayload } from 'state/account/account.services';
import { AddressParts } from 'state/usps/usps.reducers';
import { accountAddAddressToProfileRoutine } from 'state/account/account.routines';
import {
    savePrescriptionInfo,
    savePrescriberDetails,
    savePrescriptionPharmacy
} from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import {
    addTransferPrescriptionFlowTypeSelector,
    addTransferPrescriptionShowPaymentSelector,
    addTransferPrescriptionShowAddressSelector
} from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { accountCreditCardsSelector, accountProfileAddressesSelector } from 'state/account/account.selectors';

import PrescriptionLayoutFormWrapper from 'components/prescription-layout-form-wrapper/prescription-layout-form-wrapper.component';
import { HealthConditionPills } from 'components/health-conditions/health-conditions.component';
import {
    AllergiesModalContent,
    HealthConditionsModalContent
} from 'components/health-profile/health-profile.component';
import { HealthProfileBubbleUpdateEvent } from 'components/health-profile/health-profile.props';
import AddAddressForm, { AddressVerificationFailureModalContent } from 'components/add-address-form/AddAddressForm';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import PrescriptionInfoForm from 'components/prescription-info-form/prescription-info-form.component';
import PrescriberDetailsForm from 'components/prescriber-details-form/prescriber-details-form.component';
import PrescriptionPharmacyForm from 'components/pharmacy-form/pharmacy-form.component';
import PaymentForm from 'pages/secure/profile/intra-page-items/payment.item';
import ShippingAddresses from 'components/shipping-addresses/shipping-addresses.component';
import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';
import PaymentMethods from 'components/payment-methods/payment-methods.components';

import Button from 'ui-kit/button/button';
import BirdiAccordion from 'ui-kit/accordion/accordion';
import { FailureUpdateProfileModalContent } from '../profile/intra-page-items/profile-update-modal.item';

const Prescription = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentFlowType = useSelector(addTransferPrescriptionFlowTypeSelector);
    const showPaymentStep = useSelector(addTransferPrescriptionShowPaymentSelector);
    const showAddressStep = useSelector(addTransferPrescriptionShowAddressSelector);
    const cards = useSelector(accountCreditCardsSelector);
    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const { incrementStep } = useAddTransferPrescription();
    const {
        existingAllergies,
        existingConditions,
        userHasNotSubmittedAllergies,
        userHasNotSubmittedConditions
    } = useHealthConditions();
    const { isBusy: isAddAddressBusy, verifyAddress } = useAddressVerification();

    const imageData = useStaticQuery(graphql`
        query {
            newRxHealth: file(relativePath: { eq: "assets/images/new-rx-health.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            newRxPayment: file(relativePath: { eq: "assets/images/new-rx-payment.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            newRxPrescriber: file(relativePath: { eq: "assets/images/new-rx-prescriber.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            newRxPrescription: file(relativePath: { eq: "assets/images/new-rx-prescription.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            newRxShipping: file(relativePath: { eq: "assets/images/new-rx-shipping.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            transferRxHealth: file(relativePath: { eq: "assets/images/transfer-rx-health.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            transferRxPayment: file(relativePath: { eq: "assets/images/transfer-rx-payment.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            transferRxPharmacy: file(relativePath: { eq: "assets/images/transfer-rx-pharmacy.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            transferRxPrescriber: file(relativePath: { eq: "assets/images/transfer-rx-prescriber.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            transferRxPrescription: file(relativePath: { eq: "assets/images/transfer-rx-prescription.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            transferRxShipping: file(relativePath: { eq: "assets/images/transfer-rx-shipping.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    const handleToggleAllergiesClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                contentClassName: 'allergies-modal',
                bodyContent: (
                    <AllergiesModalContent
                        title={t('modals.prescription.addAllergy.title')}
                        subTitle={t('modals.prescription.addAllergy.subTitle', {
                            phoneNumber: t('modals.healthConditions.phoneNumber')
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        freeformConditionsLabel={t('components.healthConditions.labels.freeformAllergiesLabel')}
                        submitLabel={t('modals.prescription.addAllergy.submit')}
                    />
                ),
                ctas: []
            })
        );
    };
    const handleUpdateConditionsClick = ({ action, update }: HealthProfileBubbleUpdateEvent) => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <HealthConditionsWarningModalContent translation={t} />,
                ctas: [
                    {
                        label: t('modals.healthConditions.submit'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                            dispatch(action(update));
                        }
                    }
                ]
            })
        );
    };
    const handleToggleHealthConditionsClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                contentClassName: 'healthconditions-modal',
                bodyContent: (
                    <HealthConditionsModalContent
                        title={t('modals.prescription.addHealthCondition.title')}
                        subTitle={t('modals.prescription.addHealthCondition.subTitle', {
                            phoneNumber: t('modals.healthConditions.phoneNumber')
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        submitLabel={t('modals.prescription.addHealthCondition.submit')}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleAddAddressFormCancel = () => {
        dispatch(closeModal({}));
    };
    const handleAddNewAddressClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('modals.addAddressModal.title')}
                        body={
                            <AddAddressForm
                                handleFormCancel={handleAddAddressFormCancel}
                                handleFormSubmit={handleAddAddressFormSubmit}
                                centerFormSubmit={true}
                                isAddressVerifying={isAddAddressBusy}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const handleAddAddressFormSubmit = (values: ProfileObjectAddressPayload) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };
        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                dispatch(
                    accountAddAddressToProfileRoutine.trigger({
                        ...values,
                        zipcode: Zip5,
                        zipcodeFour: Zip4,
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.address')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            }
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
                dispatch(closeModal({}));
            },
            onFailure: () => {
                dispatch(
                    openModal({
                        showClose: true,
                        className: 'prescription-modal',
                        bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                        ctas: [
                            {
                                label: t('modals.healthConditions.submit'),
                                variant: 'primary',
                                onClick: () => {
                                    dispatch(closeModal({}));
                                    handleAddNewAddressClick();
                                }
                            }
                        ]
                    })
                );
            }
        });
    };

    const handlePrescriptionInfoSubmit = (values: any) => {
        dispatch(savePrescriptionInfo(values));
        incrementStep();
    };

    const handlePrescriptionPharmacySubmit = (values: any) => {
        dispatch(savePrescriptionPharmacy(values));
        incrementStep();
    };

    const handlePrescriberDetailsSubmit = (values: any) => {
        dispatch(savePrescriberDetails(values));
        incrementStep();
    };

    const formSteps = [
        {
            step: <PrescriptionInfoForm handleFormSubmit={handlePrescriptionInfoSubmit} />,
            title: t('prescriptionInfoForm.title'),
            backgroundImage:
                currentFlowType === 'Transfer' ? imageData.transferRxPrescription : imageData.newRxPrescription,
            showNextButton: false,
            gtmVPV: {
                flowType: currentFlowType,
                pagePath: '/prescription-details'
            }
        },
        {
            step: <PrescriberDetailsForm handleFormSubmit={handlePrescriberDetailsSubmit} />,
            title: t('prescriberDetailsForm.title'),
            backgroundImage:
                currentFlowType === 'Transfer' ? imageData.transferRxPrescriber : imageData.newRxPrescriber,
            showNextButton: false,
            gtmVPV: { pagePath: '/prescriber' }
        },
        {
            step: (
                <>
                    <BirdiAccordion.Toggle
                        title={t('accordions.healthConditions.title')}
                        toggleText={t('accordions.healthConditions.toggleText')}
                        includeTitleIcon={false}
                        onToggle={handleToggleHealthConditionsClick}
                        alwaysOpenContent={<HealthConditionPills conditions={existingConditions} />}
                    />
                    {userHasNotSubmittedConditions && (
                        <Row className="mb-3">
                            <Col>
                                <div className="error">{t('prescriptionLayout.errors.conditionsRequired')}</div>
                            </Col>
                        </Row>
                    )}
                    <BirdiAccordion.Toggle
                        title={t('accordions.allergies.title')}
                        toggleText={t('accordions.allergies.toggleText')}
                        includeTitleIcon={false}
                        onToggle={handleToggleAllergiesClick}
                        alwaysOpenContent={<HealthConditionPills conditions={existingAllergies} />}
                    />
                    {userHasNotSubmittedAllergies && (
                        <Row>
                            <Col>
                                <div className="error">{t('prescriptionLayout.errors.allergiesRequired')}</div>
                            </Col>
                        </Row>
                    )}
                </>
            ),
            title: t('pages.addNewPrescription.titles.tellUsAboutYourHealth'),
            backgroundImage: currentFlowType === 'Transfer' ? imageData.transferRxHealth : imageData.newRxHealth,
            dataGAFormName: `${currentFlowType}Prescription`,
            dataGAFormStepName: 'PrescriptionHealthConditions',
            showNextButton: true,
            gtmVPV: { pagePath: '/health' }
        }
    ];

    if (currentFlowType === 'Transfer') {
        formSteps.splice(1, 0, {
            step: <PrescriptionPharmacyForm handleFormSubmit={handlePrescriptionPharmacySubmit} />,
            title: t('prescriptionPharmacyForm.title'),
            backgroundImage: imageData.transferRxPharmacy,
            showNextButton: false,
            gtmVPV: { pagePath: '/pharmacy' }
        });
    }

    if (showPaymentStep) {
        formSteps.push({
            step: !cards?.length ? (
                <PaymentForm
                    centerFormSubmit={true}
                    showCancel={false}
                    instructions={'pages.addNewPrescription.instructions.addNewPayment'}
                    submitButtonText={'prescriptionLayout.next'}
                />
            ) : (
                <>
                    <PaymentMethods paymentData={allPaymentData ? allPaymentData : []} isProfile={false} />
                    <Row className="d-flex justify-content-center mt-3">
                        <Button
                            label={t('prescriptionLayout.next')}
                            type="submit"
                            onClick={() => {
                                incrementStep();
                            }}
                        />
                    </Row>
                </>
            ),
            title: t('pages.addNewPrescription.titles.addPaymentMethod'),
            backgroundImage: currentFlowType === 'Transfer' ? imageData.transferRxPayment : imageData.newRxPayment,
            showNextButton: false,
            gtmVPV: { pagePath: '/payment' }
        });
    }

    if (showAddressStep) {
        const addresses: AddressCardProps[] = profileAddresses.map((address) => ({
            defaultAddress: address.currentShipping,
            address1: address.address1,
            address2: address.address2,
            city: address.city,
            country: address.country,
            state: address.state,
            zipcode: address.zipcode,
            zipcodeFour: address.zipcodeFour,
            defaultAddressLabel: t('shipping.shipToThisAddressLabel'),
            addressType: address.addressTypeDesc,
            isProfile: false
        }));
        formSteps.push({
            step: !profileAddresses.length ? (
                <AddAddressForm
                    handleFormCancel={handleAddAddressFormCancel}
                    handleFormSubmit={handleAddAddressFormSubmit}
                    centerFormSubmit={true}
                    isAddressVerifying={isAddAddressBusy}
                    submitButtonText={'prescriptionLayout.next'}
                    showCancel={false}
                    instructions={'pages.addNewPrescription.instructions.addShippingAddress'}
                />
            ) : (
                <>
                    <ShippingAddresses
                        addressData={addresses}
                        defaultAddressLabel={t('shipping.shipToThisAddressLabel')}
                        isProfile={false}
                    />
                    <Row className="d-flex justify-content-center mt-3">
                        <Button
                            label={t('prescriptionLayout.next')}
                            type="submit"
                            onClick={() => {
                                incrementStep();
                            }}
                        />
                    </Row>
                </>
            ),
            title: t('pages.addNewPrescription.titles.addShippingAddress'),
            backgroundImage: currentFlowType === 'Transfer' ? imageData.transferRxShipping : imageData.newRxShipping,
            showNextButton: false,
            gtmVPV: { pagePath: '/shipping' }
        });
    }
    return (
        <PrescriptionLayoutFormWrapper
            eyebrowText={
                currentFlowType === 'New'
                    ? t('pages.addNewPrescription.eyebrowText')
                    : t('pages.transferPrescription.eyebrowText')
            }
            className="order-prescription-workflow-layout-form-wrapper"
            flowType={currentFlowType}
            steps={formSteps}
        />
    );
};

export default withSecurity(Prescription);
