import * as yup from 'yup';

export const NEW_PRESCRIPTION_SCHEMA = () =>
    yup.object().shape({
        drugName: yup.string().required(),
        drugForm: yup.string().required(),
        strength: yup
            .string()
            .required()
            .test('drug-strength', (value) => value?.indexOf('-') === -1),
        qty: yup
            .string()
            .required()
            .test('drug-qty', (value) => /^\+?(0|[1-9]\d*)$/.test(value))
    });

export const TRANSFER_PRESCRIPTION_SCHEMA = () =>
    yup.object().shape({
        drugName: yup.string().required(),
        drugForm: yup.string().required(),
        strength: yup
            .string()
            .required()
            .test('drug-strength', (value) => value?.indexOf('-') === -1),
        qty: yup
            .string()
            .required()
            .test('drug-qty', (value) => /^\+?(0|[1-9]\d*)$/.test(value)),
        rxNumber: yup.string().required()
    });
