import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { addTransferPrescriptionSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { PrescriptionPharmacyFormProps } from './pharmacy-form.props';
import { PharmacyTypes } from 'types/pharmacy';
import { PHARMACY_SCHEMA } from 'schema/pharmacy.schema';

import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';
import PhoneNumberText from 'ui-kit/phone-number-text/phone-number-text';

const PrescriptionPharmacyForm = ({ className, handleFormSubmit }: PrescriptionPharmacyFormProps) => {
    const classes = classNames(className);
    const { t } = useTranslation();
    const formName = 'PharmacyDetailsForm';
    const transferPrescriptionState = useSelector(addTransferPrescriptionSelector);

    const handleSubmit = (values: Partial<PharmacyTypes>) => {
        handleFormSubmit(values as PharmacyTypes);
    };

    return (
        <div className={classes}>
            <Row>
                <Col>
                    <Formik<Partial<PharmacyTypes>>
                        onSubmit={handleSubmit}
                        validationSchema={PHARMACY_SCHEMA(t('countryCode'))}
                        initialValues={{
                            pharmacyName: transferPrescriptionState.PharmacyName.length
                                ? transferPrescriptionState.PharmacyName
                                : '',
                            pharmacyAddress: transferPrescriptionState.PharmacyAddress.length
                                ? transferPrescriptionState.PharmacyAddress
                                : '',
                            pharmacyPhone: transferPrescriptionState.PharmacyPhone
                                ? transferPrescriptionState.PharmacyPhone
                                : ''
                        }}
                    >
                        {(formik: any) => (
                            <Form
                                id="pharmacy-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <Row>
                                    <Col className="d-flex justify-content-start">
                                        <p className="form-instructions">
                                            {t('prescriptionPharmacyForm.labels.pharmacyName')}
                                        </p>
                                    </Col>
                                </Row>
                                <Row xs={1}>
                                    <Col>
                                        <Text
                                            name="pharmacyName"
                                            label={t('prescriptionPharmacyForm.inputs.pharmacyName')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.pharmacyName
                                                    ? t('forms.errorMessages.requiredField', {
                                                          label: t('prescriptionPharmacyForm.inputs.pharmacyName')
                                                      })
                                                    : undefined
                                            }
                                            touched={formik.touched.pharmacyName}
                                            value={formik.values?.pharmacyName}
                                            defaultValue={formik.initialValues.pharmacyName}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-start">
                                        <p className="form-instructions">
                                            {t('prescriptionPharmacyForm.labels.pharmacyAddress')}
                                        </p>
                                    </Col>
                                </Row>
                                <Row xs={1}>
                                    <Col>
                                        <Text
                                            name="pharmacyAddress"
                                            label={t('prescriptionPharmacyForm.inputs.pharmacyAddress')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.pharmacyAddress
                                                    ? t('forms.errorMessages.requiredField', {
                                                          label: t('prescriptionPharmacyForm.inputs.pharmacyAddress')
                                                      })
                                                    : undefined
                                            }
                                            touched={formik.touched.pharmacyAddress}
                                            value={formik.values?.pharmacyAddress}
                                            defaultValue={formik.initialValues.pharmacyAddress}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-start">
                                        <p className="form-instructions">
                                            {t('prescriptionPharmacyForm.labels.pharmacyPhone')}
                                        </p>
                                    </Col>
                                </Row>
                                <Row xs={1}>
                                    <Col>
                                        <PhoneNumberText
                                            name="pharmacyPhone"
                                            label={t('prescriptionPharmacyForm.inputs.pharmacyPhone')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.pharmacyPhone
                                                    ? t('prescriberDetailsForm.errors.phoneNumber')
                                                    : undefined
                                            }
                                            touched={formik.touched.pharmacyPhone}
                                            value={formik.values.pharmacyPhone}
                                            countryCode={t(`countryCode`)}
                                            defaultValue={formik.initialValues.pharmacyPhone}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex flex-column align-items-center">
                                        <Button
                                            label={t('prescriptionLayout.next')}
                                            variant="primary"
                                            type="submit"
                                            dataGAFormName={formName}
                                            dataGAFormStep="2-Pharmacy"
                                            dataGAFormStepName="Pharmacy"
                                            disabled={!formik.values.pharmacyName.length && !formik.dirty}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </div>
    );
};

export default PrescriptionPharmacyForm;
