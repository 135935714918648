import * as yup from 'yup';
import { validatePhoneNumber } from 'schema/validation/phone-number';

export const PRRESCRIBER_SCHEMA = (countryCode: 'US' | 'CA') =>
    yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        phoneNumber: yup
            .string()
            .test(
                'Phone Number valiation',
                (value: string | undefined, context: yup.TestContext<Record<string, any>>) =>
                    validatePhoneNumber(value, context, countryCode)
            ),
        faxNumber: yup.string(),
        address: yup.string().required(),
        city: yup.string().required(),
        state: yup.string().required(),
        zip: yup.string()
    });
