import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { addTransferPrescriptionSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';

import { stateOptions } from 'const/options';
import { PrescriberTypes } from 'types/prescriber';
import { PRRESCRIBER_SCHEMA } from 'schema/prescriber.schema';

import Button from 'ui-kit/button/button';
import FormSelect from 'ui-kit/form-select/form-select';
import PhoneNumberText from 'ui-kit/phone-number-text/phone-number-text';
import Text from 'ui-kit/text/text';

import { PrescriberDetailsFormProps } from './prescriber-details-form.props';

const PrescriberDetailsForm = ({ className, handleFormSubmit }: PrescriberDetailsFormProps) => {
    const classes = classNames(className);
    const { t } = useTranslation();
    const formName = 'PrescriberDetailsForm';
    const transferPrescriptionState = useSelector(addTransferPrescriptionSelector);

    const handleSubmit = (values: Partial<PrescriberTypes>) => {
        handleFormSubmit(values as PrescriberTypes);
    };

    return (
        <div className={classes}>
            <Row>
                <Col>
                    <Formik<Partial<PrescriberTypes>>
                        onSubmit={handleSubmit}
                        validationSchema={PRRESCRIBER_SCHEMA(t('countryCode'))}
                        initialValues={{
                            firstName: transferPrescriptionState.PrescriberName.split(' ')[0],
                            lastName: transferPrescriptionState.PrescriberName.split(' ')[1],
                            phoneNumber: transferPrescriptionState.PrescriberPhone,
                            faxNumber: transferPrescriptionState.PrescriberFax,
                            address: transferPrescriptionState.PrescriberAddress,
                            city: transferPrescriptionState.PrescriberCity,
                            state: transferPrescriptionState.PrescriberState,
                            zip: transferPrescriptionState.PrescriberZip
                        }}
                    >
                        {(formik: any) => (
                            <Form
                                id="prescriber-details-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <Row>
                                    <Col className="d-flex justify-content-start">
                                        <p className="form-instructions">
                                            {t('prescriberDetailsForm.labels.prescriberDetails')}
                                        </p>
                                    </Col>
                                </Row>
                                <Row xs={1} lg={2}>
                                    <Col>
                                        <Text
                                            name="firstName"
                                            label={t('prescriberDetailsForm.inputs.firstName')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.firstName
                                                    ? t('forms.errorMessages.requiredField', {
                                                          label: t('prescriberDetailsForm.inputs.firstName')
                                                      })
                                                    : undefined
                                            }
                                            touched={formik.touched.firstName}
                                            value={formik.values?.firstName}
                                            defaultValue={formik.initialValues.firstName}
                                        />
                                    </Col>
                                    <Col>
                                        <Text
                                            name="lastName"
                                            label={t('prescriberDetailsForm.inputs.lastName')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.lastName
                                                    ? t('forms.errorMessages.requiredField', {
                                                          label: t('prescriberDetailsForm.inputs.lastName')
                                                      })
                                                    : undefined
                                            }
                                            touched={formik.touched.lastName}
                                            value={formik.values?.lastName}
                                            defaultValue={formik.initialValues.lastName}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} lg={12}>
                                        <Text
                                            name="address"
                                            label={t('prescriberDetailsForm.inputs.address')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.address
                                                    ? t('forms.errorMessages.requiredField', {
                                                          label: t('prescriberDetailsForm.inputs.address')
                                                      })
                                                    : undefined
                                            }
                                            touched={formik.touched.address}
                                            value={formik.values?.address}
                                            defaultValue={formik.initialValues.address}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} lg={4}>
                                        <Text
                                            name="city"
                                            label={t('prescriberDetailsForm.inputs.city')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.city
                                                    ? t('forms.errorMessages.requiredField', {
                                                          label: t('prescriberDetailsForm.inputs.city')
                                                      })
                                                    : undefined
                                            }
                                            touched={formik.touched.city}
                                            value={formik.values?.city}
                                            defaultValue={formik.initialValues.city}
                                        />
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <Field
                                            id="state"
                                            name="state"
                                            options={stateOptions}
                                            component={FormSelect}
                                            value={formik.values?.state}
                                            placeholder={t('prescriberDetailsForm.inputs.state')}
                                            errors={
                                                formik.errors?.state
                                                    ? t('forms.errorMessages.requiredField', {
                                                          label: t('prescriberDetailsForm.inputs.state')
                                                      })
                                                    : undefined
                                            }
                                            touched={formik.touched.state}
                                        />
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <Text
                                            name="zip"
                                            label={t('prescriberDetailsForm.inputs.zip')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.zip
                                                    ? t('forms.errorMessages.requiredField', {
                                                          label: t('prescriberDetailsForm.inputs.zip')
                                                      })
                                                    : undefined
                                            }
                                            touched={formik.touched.zip}
                                            value={formik.values?.zip}
                                            maxLength={10}
                                            defaultValue={formik.initialValues.zip}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-start">
                                        <p className="form-instructions">
                                            {t('prescriberDetailsForm.labels.phoneNumber')}
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <PhoneNumberText
                                            name="phoneNumber"
                                            label={t('prescriberDetailsForm.inputs.phoneNumber')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.phoneNumber
                                                    ? t('prescriberDetailsForm.errors.phoneNumber')
                                                    : undefined
                                            }
                                            touched={formik.touched.phoneNumber}
                                            value={formik.values.phoneNumber}
                                            countryCode={t(`countryCode`)}
                                            defaultValue={formik.initialValues.phoneNumber}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <PhoneNumberText
                                            name="faxNumber"
                                            label={t('prescriberDetailsForm.inputs.faxNumber')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.faxNumber
                                                    ? t('prescriberDetailsForm.errors.phoneNumber')
                                                    : undefined
                                            }
                                            touched={formik.touched.faxNumber}
                                            value={formik.values.faxNumber}
                                            countryCode={t(`countryCode`)}
                                            defaultValue={formik.initialValues.faxNumber}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex flex-column align-items-center">
                                        <Button
                                            label={t('prescriptionLayout.next')}
                                            variant="primary"
                                            type="submit"
                                            dataGAFormName={formName}
                                            dataGAFormStep="2-Prescriber"
                                            dataGAFormStepName="Prescriber"
                                            disabled={!formik.values.firstName.length && !formik.dirty}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </div>
    );
};

export default PrescriberDetailsForm;
